import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar";
import JobList from "../components/JobList";
import {graphql} from "gatsby";
import Cookies from 'js-cookie';


import Marquee from "react-fast-marquee";
import Layout from "../components/Layout";
import MyPortableText from "../components/MyPortableText";
import BlogTag from "../components/BlogTag";
import Head from "../components/Head";
import {useUserConsent} from "../utils/UserConsentContext";

export const query = graphql`
    {
  allSanityDatenschutz {
    edges {
      node {
        title,
        _rawBody
      }
    }
  }
}
`


const Datenschutz = ({data}) => {

    const daten = data.allSanityDatenschutz.edges[0].node;

    const [showCookieBanner, setShowCookieBanner] = useState(false);

    const { consent, setConsent } = useUserConsent();
    const [ localConsent, setLocalConsent ] = useState(consent);

    useEffect(() => {
        const savedConsent = Cookies.get('eniosol-cookie-consent');
        if (savedConsent) {
            const consentObject = JSON.parse(savedConsent);
            setLocalConsent(consentObject);
        }
    }, []);

    const handleConsent = () => {
        Cookies.set('eniosol-cookie-consent', JSON.stringify(localConsent), { expires: 365 });
        setShowCookieBanner(false);
        setConsent(localConsent);
        if (!localConsent.marketing) {
            window.location.reload();
        }
    };

    const handleConsentAll = () => {
        setConsent({...consent, marketing: true});
        setLocalConsent({...localConsent, marketing: true});
        Cookies.set('eniosol-cookie-consent', JSON.stringify(localConsent), { expires: 365 });
        setShowCookieBanner(false);
    };

    return (
        <Layout>
            <Head title="Datenschutz"/>
            <Marquee gradient={false} speed={40}>
                <div className="marqueeText color-orange">
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                    <span className="marqueeText__inner">DATENSCHUTZ</span>
                </div>
            </Marquee>
            <div className="datenschutzPage blogcontent">
                <div className="blogintro__content">
                    <div className="blogintro__left">
                    </div>
                    <div className="blogintro__right">
                        <h4>{daten.title}</h4>
                    </div>
                </div>
                <div className="blog__content">
                    <MyPortableText value={daten._rawBody} />
                </div>
                <div className="blog__content">
                    <div className="cookiebanner_alt">
                        <div className="cookiebanner__inner">
                            <h6 className="cookiebanner__headline uppercase">Cookie Einstellungen</h6>
                            <p className="cookiebanner__text">
                                Diese Website verwendet Cookies, um die Benutzerfreundlichkeit zu verbessern. Durch die Nutzung unserer Website stimmen Sie allen Cookies gemäß unserer Datenschutzrichtlinie zu.
                            </p>
                            <div className="acceptancecontainer cookiebanner__acceptance technisch">
                                <label className="cbcontainer">
                                    <input type="checkbox" id={"technical"} checked={consent.technical} disabled={true}/>
                                    <span className="checkmark hover-moveup" id="dsgvocircle"></span>
                                </label>
                                <p className="smalltext">
                                    Technisch
                                </p>
                            </div>
                            <div className="acceptancecontainer cookiebanner__acceptance">
                                <label className="cbcontainer">
                                    <input type="checkbox" id={"marketing"} checked={localConsent.marketing} onChange={() => setLocalConsent({...localConsent, marketing: !localConsent.marketing})}/>
                                    <span className="checkmark hover-moveup" id="dsgvocircle"></span>
                                </label>
                                <p className="smalltext">
                                    Marketing
                                </p>
                            </div>
                            <div className="cookiebanner__btns">
                                <div onClick={handleConsent} className="btn white outline landingbtn smalltext bold uppercase">
                                    Auswahl Speichern
                                </div>
                                <div onClick={handleConsentAll} className="btn filled white landingbtn smalltext bold uppercase">
                                    Alle Akzeptieren
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Datenschutz;
